import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import Home from "./pages/Home";
import Blogs from "./pages/NormaBlog/Blogs";
import Contact from "./pages/Contactanos";
import NoPage from "./pages/NoPage";
import Normativida from "./pages/NormaBlog/Normatividad";
import CursosPage from "./pages/cursoswebinar/Cursosdetalles";
import CursosHome from "./pages/cursoswebinar/CursosHome";
import SimuladorHome from "./pages/estatico/Simulador";
import NormaDetalles from "./pages/NormaBlog/NormaDetalles";
import RegistroAlumno from "./pages/RegistroAlumno";
import Confirmar from "./pages/ConfirmarCorreo";
import LoginAlumno from "./pages/LoginAlumno";
import BlogsDetalles from "./pages/NormaBlog/Blogdetalles";
import AulaVirtaul from "./pages/aulaVirtual/AulaVirtaul";
import AulaDetalles from "./pages/aulaVirtual/AulaDetalles";
import Editpassword from "./pages/editContraseña";
import PreIncritos from "./pages/cursoswebinar/PreInscripcion";
import WebinarPage from "./pages/cursoswebinar/Webinardetalles";
import Concursos from "./pages/concurso/Concursos";
import DetallesConcurso from "./pages/concurso/ConcursoDetalles";
import Nosotros from "./pages/estatico/oboutass";
import ConcursoIns from "./pages/concurso/concursoIns";
import ReactGA from "react-ga";

const ProtectedRoute = ({ element }) => {
  // Comprueba si el usuario está autenticado
  if (!sessionStorage.getItem("userName")) {
    // Si no está autenticado, redirige al componente de inicio de sesión
    return <Navigate to="/LoginAlumno" replace />;
  }

  return element;
};

// import AddItem from './components/AddItem';
// import ItemList from './components/ItemList';
// import EditItem from './components/EditItem';

ReactGA.initialize("G-2QBH6VMR8G");
const App = () => {
  useEffect(() => {
    const unlisten = ReactGA.pageview(
      window.location.pathname + window.location.search
    );
    return () => {
      unlisten();
    };
  }, []);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />

          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/Blog/:id" element={<BlogsDetalles />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/normatividad" element={<Normativida />} />
        <Route path="/CursosHome" element={<CursosHome />} />
        <Route path="/CursosPage/:slug" element={<CursosPage />} />
        <Route path="/WebinarPage/:slug" element={<WebinarPage />} />
        {/* Simulador */}
        <Route path="/SimuladorHome" element={<SimuladorHome />} />
        <Route path="/Normadetalles/:slug" element={<NormaDetalles />} />
        {/*         Login ALumno */}
        <Route path="/RegistroAlumno" element={<RegistroAlumno />} />
        <Route path="/Confirmar/:token" element={<Confirmar />} />

        <Route path="/Nosotros-stemis" element={<Nosotros />} />

        <Route path="/LoginAlumno" element={<LoginAlumno />} />
        {/* <Route path="/webinar/:slug" element={<Webinar />} /> */}
        <Route path="/pre-incripcion/:id" element={<PreIncritos />} />
        <Route path="/Concursos" element={<Concursos />} />
        <Route path="/Detalles/Concurso/:slug" element={<DetallesConcurso />} />

        <Route path="/concurso/inscripcion/:id" element={<ConcursoIns />} />

        {/* <Route path="/descargarBrochure/:id" element={<DescargarBrochure />} /> */}
        <Route
          path="/AulaVirtaul/:id"
          element={<ProtectedRoute element={<AulaVirtaul />} />}
        />
        <Route
          path="/AulaDetalles/:slug"
          element={<ProtectedRoute element={<AulaDetalles />} />}
        />
        <Route
          path="/editar-contraeña/:id"
          element={<ProtectedRoute element={<Editpassword />} />}
        />
      </Routes>
    </BrowserRouter>

    // <Router>
    //   <div>
    //     <nav>
    //       <ul>
    //         <li>
    //           <Link to="/">Inicio</Link>
    //         </li>
    //         <li>
    //           <Link to="/add">Agregar Item</Link>
    //         </li>
    //       </ul>
    //     </nav>

    //     <hr />

    //     {/* <Route path="/" component={ItemList} />
    //     <Route path="/add" component={ItemList} /> */}

    //     {/* <Route path="/" exact render={() => <ItemList items={items} onDelete={handleDelete} onEdit={handleEdit} />} />
    //     <Route path="/add" render={() => <AddItem onAdd={handleAdd} />} />  */}
    //     {/* <Route path="/edit/:id" render={(props) => <EditItem item={} onSave={handleSave} />} /> */}
    //   </div>
    // </Router>
  );
};

export default App;

import Footer from "../../components/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Swal from "sweetalert2";

const PreIncritos = () => {
  const [cursos, setCursos] = useState([]);
  const [universidades, setUniversidades] = useState([]);
  const [departamentos, setDepartamento] = useState([]);
  const [mostrarColegio, setMostrarColegio] = useState(false);
  const [colegioAbogados, setColegioAbogados] = useState("");
  const [enviando, setEnviando] = useState(false);
  const [formData, setFormData] = useState({
    curso: "",
    departamento: "",
    nombre: "",
    email: "",
    telefono: "",
    apellidos: "",
    gradoAcademico: "",
    enteraste: "",
    uni: "",
    dni: "",
    colegio: "",
    certificado: false,
    PDFile: null,
    notificaciones: false,
    inscripcion: false,
    created: new Date(),
  });
  const [alerta, setAlerta] = useState({ mostrar: false, mensaje: "" });
  const { id } = useParams();

  const obtenerCursos = () => {
    axios
      .get(`${window.BACKEND_URL}curso/${id}`)
      .then((response) => setCursos(response.data))
      .catch((error) => console.error("Error obteniendo cursos:", error));
  };

  const mostrarAlerta = (mensaje) => {
    setAlerta({ mostrar: true, mensaje });
    setTimeout(() => {
      setAlerta({ mostrar: false, mensaje: "" });
    }, 3000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualizar el estado de formData con el nuevo valor
    setFormData({
      ...formData,
      [name]: value,
    });

    // Si el valor seleccionado es "PROFESIONAL" o "POST GRADO", mostrar el campo de selección del colegio de abogados
    if (value === "PROFESIONAL" || value === "POST GRADO") {
      setMostrarColegio(true);
    } else {
      // Si no es "PROFESIONAL" ni "POST GRADO", ocultar el campo de selección del colegio de abogados
      setMostrarColegio(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key === "curso") {
          formDataToSend.append("curso", cursos.nombre);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }
      const response = await axios.post(
        `${window.BACKEND_URL}inscripcion`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEnviando(false);
      Swal.fire(
        "¡Preinscripción exitosa! Se envió un mensaje al correo proporcionado a efecto de culminar su inscripción.",
        "",
        "success"
      );
      window.history.back();
    } catch (error) {
      setEnviando(false);
      console.error("Error al guardar el mensaje:", error);
      if (error.response && error.response.status === 400) {
        mostrarAlerta(error.response.data.message);
      } else {
        console.error("Error al guardar el mensaje:", error);
        mostrarAlerta("Error al enviar el mensaje");
      }
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  useEffect(() => {
    obtenerCursos();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getUni = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}universidades`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });
        setUniversidades(response.data);
      } catch (error) {
        console.error("Error al obtener los universidades:", error);
      }
    };
    getUni();
  }, []);

  useEffect(() => {
    const getWebinar = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}departamentos`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });
        setDepartamento(response.data);
      } catch (error) {
        console.error("Error al obtener los Webinarios:", error);
      }
    };
    getWebinar();
  }, []);

  const handlePhotoChange1 = (e) => {
    const selectedPhoto = e.target.files[0];
    setFormData({
      ...formData,
      PDFile: selectedPhoto,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  return (
    <>
      <Navbar />
      <section className="position-relative pt-4 pt-sm-5">
        <figure className="position-absolute top-0 end-0 mb-n5">
          <svg width="822.2px" height="301.9px" viewBox="0 0 822.2 301.9">
            <path
              className="fill-warning opacity-4"
              d="M752.5,51.9c-4.5,3.9-8.9,7.8-13.4,11.8c-51.5,45.3-104.8,92.2-171.7,101.4c-39.9,5.5-80.2-3.4-119.2-12.1 c-32.3-7.2-65.6-14.6-98.9-13.9c-66.5,1.3-128.9,35.2-175.7,64.6c-11.9,7.5-23.9,15.3-35.5,22.8c-40.5,26.4-82.5,53.8-128.4,70.7 c-2.1,0.8-4.2,1.5-6.2,2.2L0,301.9c3.3-1.1,6.7-2.3,10.2-3.5c46.1-17,88.1-44.4,128.7-70.9c11.6-7.6,23.6-15.4,35.4-22.8 c46.7-29.3,108.9-63.1,175.1-64.4c33.1-0.6,66.4,6.8,98.6,13.9c39.1,8.7,79.6,17.7,119.7,12.1C634.8,157,688.3,110,740,64.6 c4.5-3.9,9-7.9,13.4-11.8C773.8,35,797,16.4,822.2,1l-0.7-1C796.2,15.4,773,34,752.5,51.9z"
            ></path>
          </svg>
        </figure>

        <div className="container position-relative z-index-9">
          <div className="row g-4 g-sm-5 justify-content-between">
            <div className="col-lg-6 position-relative">
              <div className="bg-primary bg-opacity-10 rounded-3 p-4 p-sm-5">
                <h2 className="mb-3">Pre inscribete al curso</h2>

                {alerta.mostrar && (
                  <div className="alert alert-success mt-3" role="alert">
                    {alerta.mensaje}
                  </div>
                )}

                <form
                  className="row g-4 g-sm-3 mt-2 mb-0"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12">
                    <label className="form-label">
                      Curso <span className="text-danger">* </span>
                    </label>

                    <input
                      type="text"
                      className="form-control "
                      aria-label="curso"
                      value={cursos.nombre}
                      disabled
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-label">
                        Nombres <span className="text-danger">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-uppercase" // Agregar la clase text-uppercase para convertir el texto a mayúsculas
                        aria-label="First name"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">
                        Apellidos <span className="text-danger">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        aria-label="Last name"
                        name="apellidos"
                        id="apellidos"
                        value={formData.apellidos}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-label">
                        Departamento <span className="text-danger">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        aria-label="First name"
                        name="departamento"
                        id="departamento"
                        placeholder="Ejemplo: Arequipa, Lima, etc."
                        value={formData.departamento}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">* </span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        aria-label="First name"
                        id="email"
                        name="email"
                        placeholder="Ejemplo@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-label">
                        Número de celular
                        <span className="text-danger">* </span>
                      </label>
                      <input
                        type="number"
                        name="telefono"
                        id="telefono"
                        className="form-control"
                        aria-label="Mobile number"
                        value={formData.telefono}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">
                        Número de DNI <span className="text-danger">* </span>
                      </label>
                      <input
                        type="number"
                        name="dni"
                        id="dni"
                        className="form-control"
                        aria-label="Mobile number"
                        value={formData.dni}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Grado Académico <span className="text-danger">* </span>
                    </label>
                    <select
                      name="gradoAcademico"
                      id="gradoAcademico"
                      className="form-select"
                      aria-label="Grado Académico"
                      value={formData.gradoAcademico}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Selecciona un grado académico
                      </option>
                      <option value="ESTUDIANTE UNIVERSITARIO">
                        Estudiante universitario
                      </option>
                      <option value="EGRESADO">Egresado</option>
                      <option value="Bachiller">Bachiller</option>
                      <option value="PROFESIONAL">Profesional</option>
                      <option value="POST GRADO">Post grado </option>
                    </select>
                  </div>
                  {formData.gradoAcademico === "PROFESIONAL" ||
                  formData.gradoAcademico === "POST GRADO" ? (
                    <div className="col-12">
                      <label className="form-label">Colegio de abogados</label>
                      <select
                        name="colegio"
                        id="colegio"
                        className="form-select"
                        aria-label="Grado Académico"
                        value={formData.colegio}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Selecciona un colegio de abogados
                        </option>
                        {departamentos.map((depadata) => (
                          <option key={depadata._id} value={depadata._id}>
                            {depadata.Nombre}
                          </option>
                        ))}
                        <option value="Otros">Otros</option>
                      </select>
                    </div>
                  ) : null}

                  <div className="col-12">
                    <label className="form-label">
                      ¿A que universidad perteneces?{" "}
                      <span className="text-danger">* </span>
                    </label>
                    <select
                      name="uni"
                      id="uni"
                      className="form-select"
                      aria-label="Grado Académico"
                      value={formData.uni}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Selecciona una Universidad
                      </option>
                      {universidades.map((unidata) => (
                        <option key={unidata._id} value={unidata._id}>
                          {unidata.Nombre}
                        </option>
                      ))}

                      <option value="Otros">Otros</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="PDFile">
                      Documento que acredite grado académico:
                    </label>

                    <input
                      type="file"
                      className="form-control"
                      id="PDFile"
                      name="PDFile"
                      onChange={handlePhotoChange1}
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      ¿Cómo te enteraste del curso?{" "}
                      <span className="text-danger">* </span>
                    </label>
                    <select
                      name="enteraste"
                      id="enteraste"
                      className="form-select"
                      aria-label="Grado Académico"
                      value={formData.enteraste}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Selecciona una opccion
                      </option>
                      <option value="Pagina">Página Web</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="WhatsApp">WhatsApp</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Otros">Otros</option>
                    </select>
                  </div>
                  <div className="mb-1 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="notificaciones"
                      name="notificaciones"
                      checked={formData.notificaciones}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="notificaciones"
                    >
                      Autoriza que se le envié información sobre desarrollo de
                      cursos organizados por Stemis
                    </label>
                  </div>
                  <div className="mb-1 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="certificado"
                      name="certificado"
                      checked={formData.certificado}
                      onChange={handleCheckboxChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="certificado">
                      La información proporcionada respecto a sus datos
                      ingresados, así como el correo proporcionado, están
                      correctamente redactados a efecto de generar una futura
                      emisión de certificados con dicha información
                    </label>
                  </div>
                  <div className="mb-1 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inscripcion"
                      name="inscripcion"
                      checked={formData.inscripcion}
                      onChange={handleCheckboxChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="inscripcion">
                      Para efectos de la presente inscripción se tendrá en
                      cuenta la información proporcionada como cierta con efecto
                      de declaración jurada
                    </label>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <div className="d-grid">
                        <button
                          type="button"
                          className="btn btn-lg btn-secondary mb-0"
                          onClick={handleCancel}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary mb-0"
                          disabled={enviando}
                        >
                          {enviando ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Cargando...
                              </span>
                            </>
                          ) : (
                            <>Pre-incrpcion</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-xl-5 text-center">
              {/* Title */}
              <h3>No pierdas la oportunidad de pertenecer al grupo Stemis</h3>

              <img
                src="/assets/images/logostemis/imagen registro.png"
                className="rounded"
                alt=""
              />

              {/* Avatar group and rating */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PreIncritos;

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const CursosHome = () => {
  const [cursos, setCursos] = useState([]);
  const [webinar, setWebinar] = useState([]);
  const [loading, setLoading] = useState(true);
  const cursosOrdenados = [...cursos].sort(
    (a, b) => new Date(b.created) - new Date(a.created)
  );
  const obtenerCursos = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}curso/listado`, {
        headers: {
          Authorization: `${localStorage.getItem("TokenPublic")}`,
          "Content-Type": "application/json",
          "X-Rol": `${localStorage.getItem("Rol")}`,
        },
      });
      setCursos(response.data);
    } catch (error) {
      console.error("Error fetching cursos", error);
    } finally {
      // Indicar que la carga ha finalizado
      setLoading(false);
    }
  };
  const getWebinar = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}Webinarios/Listado/datos`,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        }
      );
      setWebinar(response.data);
    } catch (error) {
      console.error("Error al obtener los Webinarios:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    obtenerCursos();
    getWebinar();
    window.scrollTo(0, 0);
  }, []);

  const renderDificultad = (nivel) => {
    let texto;

    switch (nivel) {
      case 1:
        texto = "Básico";
        break;
      case 2:
      case 3:
        texto = "Intermedio";
        break;
      case 4:
      case 5:
        texto = "Avanzado";
        break;
      default:
        texto = "Desconocido";
    }

    return <>{texto}</>;
  };
  return (
    <>
      <Navbar />

      <section
        className="bg-dark align-items-center d-flex"
        style={{
          background:
            "url(/assets/images/stemisp/banercursos.png) no-repeat center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">
                Especialízate, aprende y <br /> crece con nosotros
              </h2>
            </div>
          </div>
        </div>
      </section>

      {/*   Cards de los cursos  */}
      <section className="pt-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2 style={{ color: "#009688" }}>Cursos</h2>
              </div>

              <div className="col-md-6 d-flex justify-content-end">
                <Link
                  className="btn btn-sm text-white"
                  to="/LoginAlumno"
                  style={{ backgroundColor: "#009688" }}
                >
                  <i className="fa-solid fa-right-to-bracket me-2"></i>
                  Ingresar al Aula virtual
                </Link>
              </div>
            </div>

            {loading ? (
              <div className="text-center">
                <img
                  src="/assets/images/stemisp/gatitos.gif"
                  alt="Cargando..."
                />

                <p>Cargando cursos...</p>
              </div>
            ) : (
              cursosOrdenados.map((curso) => (
                <div className="col-lg-10 col-xxl-6">
                  <Link to={`/CursosPage/${curso.slug}`}>
                    <div className="card rounded overflow-hidden shadow">
                      <div className="row g-0">
                        {/* Image */}
                        <div className="col-md-4">
                          <img
                            src={`${window.BACKEND_URL}uploads/${curso.banerimg}`}
                            alt="card image"
                          />
                        </div>

                        {/* Card body */}
                        <div className="col-md-8">
                          <div className="card-body">
                            {/* Title */}
                            <div className="d-flex justify-content-between mb-2">
                              <h5 className="card-title mb-n3">
                                <p>{curso.nombre}</p>
                              </h5>
                            </div>

                            <ul className="list-inline mb-1">
                              <li className="list-inline-item h6 fw-light mb-1 mb-sm-0">
                                <i className="far fa-clock text-danger me-2"></i>
                                {curso.horas}h Académicas
                              </li>
                              <li className="list-inline-item h6 fw-light mb-1 mb-sm-0">
                                <i className="fas fa-table text-orange me-2"></i>
                                {curso.dias}
                              </li>
                              <li className="list-inline-item h6 fw-light">
                                <i className="fas fa-signal text-success me-2"></i>
                                {renderDificultad(curso.dificultad)}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
      {/* Card de webinar */}
      <section className="pt-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <h2 style={{ color: "#009688" }}>Webinar</h2>
            {loading ? (
              <div className="text-center">
                <img
                  src="/assets/images/stemisp/gatitos.gif"
                  alt="Cargando..."
                />
                <p>Cargando Webinar...</p>
              </div>
            ) : (
              <div className="tab-content">
                {/* Content START */}
                <div
                  className="tab-pane fade show active"
                  role="tabpanel"
                  aria-labelledby="course-pills-tab-1"
                >
                  <div className="row g-4">
                    {webinar
                      .sort(
                        (a, b) =>
                          new Date(b.fechaInicio) - new Date(a.fechaInicio)
                      ) // Ordenar webinars
                      .map((webinar, index) => (
                        <div key={index} className="col-sm-6 col-lg-4 col-xl-3">
                          <div className="card shadow h-100">
                            <Link to={`/WebinarPage/${webinar.slug}`}>
                              <img
                                src={`${window.BACKEND_URL}uploads/${webinar.photo}`}
                                className="card-img-top"
                                alt="course image"
                              />
                            </Link>
                            <div className="card-body pb-0">
                              <h6 className="card-title fw-normal text-center">
                                <Link to={`/WebinarPage/${webinar.slug}`}>
                                  {webinar.titulo}
                                </Link>
                              </h6>
                            </div>

                            {/* Card footer */}
                            <div className="card-footer pt-0 pb-3">
                              <hr />
                              <div className="d-flex justify-content-between ">
                                <span className="h6 fw-light mb-0">
                                  <i className="far fa-clock text-danger me-2"></i>
                                  {new Date(webinar.fechaInicio).getDate()}/
                                  {new Date(webinar.fechaInicio).getMonth() + 1}
                                  /{new Date(webinar.fechaInicio).getFullYear()}
                                </span>
                                <li className="list-inline-item h6 fw-light">
                                  <i className="fas fa-certificate text-warning me-2"></i>
                                  Costancia: S/{webinar.precio}
                                </li>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* Row END */}
                </div>
              </div>
            )}
            {/* Card item END */}
          </div>
        </div>
      </section>

      {/* fin de los cads */}
      <section className="p-0 mb-6 pt-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative z-index-1">
              {/* Image */}
              <div className="d-none d-lg-block position-absolute mb-n5 bottom-0 start-0 ms-3 ms-xl-5">
                <img
                  src="/assets/images/stemisp/temis con libreta.svg"
                  alt=""
                  style={{ width: "300px", height: "auto" }}
                />
              </div>

              <div className="position-absolute top-0 end-0 mt-n4 me-5">
                <img src="/assets/images/client/pencil.svg" alt="" />
              </div>
              <div className="position-absolute bottom-0 start-50 mb-n4">
                <img
                  src="assets/images/client/graduated.svg"
                  className="rotate-74"
                  alt=""
                />
              </div>

              <div className="bg-grad-pink p-4 p-sm-5 rounded position-relative z-index-n1 overflow-hidden">
                {/* SVG decoration */}
                <figure className="position-absolute top-0 start-0 mt-3 ms-n3 opacity-5">
                  <svg
                    width="818.6px"
                    height="235.1px"
                    viewBox="0 0 818.6 235.1"
                  >
                    <path
                      className="fill-white"
                      d="M735,226.3c-5.7,0.6-11.5,1.1-17.2,1.7c-66.2,6.8-134.7,13.7-192.6-16.6c-34.6-18.1-61.4-47.9-87.3-76.7 c-21.4-23.8-43.6-48.5-70.2-66.7c-53.2-36.4-121.6-44.8-175.1-48c-13.6-0.8-27.5-1.4-40.9-1.9c-46.9-1.9-95.4-3.9-141.2-16.5 C8.3,1.2,6.2,0.6,4.2,0H0c3.3,1,6.6,2,10,3c46,12.5,94.5,14.6,141.5,16.5c13.4,0.6,27.3,1.1,40.8,1.9 c53.4,3.2,121.5,11.5,174.5,47.7c26.5,18.1,48.6,42.7,70,66.5c26,28.9,52.9,58.8,87.7,76.9c58.3,30.5,127,23.5,193.3,16.7 c5.8-0.6,11.5-1.2,17.2-1.7c26.2-2.6,55-4.2,83.5-2.2v-1.2C790,222,761.2,223.7,735,226.3z"
                    ></path>
                  </svg>
                </figure>
                {/* SVG decoration */}
                <figure className="position-absolute top-50 start-0 translate-middle-y ms-5">
                  <svg width="473px" height="234px">
                    <path
                      fillrule="evenodd"
                      opacity="0.051"
                      fill="rgb(255, 255, 255)"
                      d="M0.004,222.303 L364.497,-0.004 L472.998,32.563 L100.551,233.991 L0.004,222.303 Z"
                    />
                  </svg>
                </figure>
                {/* SVG decoration */}
                <figure className="position-absolute top-50 end-0 translate-middle-y">
                  <svg width="355.6px" height="396.1px">
                    <path
                      className="fill-danger rotate-10"
                      d="M32.8,364.1c16.1-14.7,36-21.5,56.8-26.7c20-5.1,40.5-9.7,57.8-21.4c35.7-24.3,51.1-68.5,57.2-109.4 c6.8-45.7,4.6-93.7,21.6-137.5c8.3-21.4,22.3-41.4,43.3-51.9c17.4-8.7,36.2-7.9,54.2-1.5c10.2,3.6,19.8,8.5,29.4,13.5l2.5-4.3 c-2.7-1.4-5.4-2.8-8.2-4.2c-15.8-8-32.9-15.3-50.9-15.2C276,5.6,256.9,16,243.3,31c-16.6,18.3-25.3,42.2-30.5,66 c-5,22.9-6.8,46.3-8.8,69.6c-3.9,44.4-9.7,92.8-40.1,128c-7.1,8.2-15.4,15.4-24.9,20.8c-9.3,5.4-19.5,8.9-29.8,11.8 c-20.2,5.7-41.3,9.1-59.9,19.2c-19.3,10.4-35.1,27.2-44.2,47.1c0,0,0,0.1,0,0.1l4.4,2.6C15,384,22.9,373.1,32.8,364.1z"
                    />
                  </svg>
                </figure>
                <div className="row g-3 align-items-center justify-content-lg-end position-relative py-4">
                  {/* Title */}
                  <div className="col-md-6">
                    <h2 className="text-white">
                      ¿Quieres ser el primero en conocer los nuevos cursos?
                    </h2>
                  </div>
                  {/* Button */}
                  <div className="col-md-6 col-lg-3 text-md-end">
                    <Link
                      to="/Contact"
                      className="btn btn-white mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      Contactanos
                    </Link>
                  </div>
                </div>
                {/* Row END */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default CursosHome;

import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contactanos from "../../components/Contactanos";

const DetallesConcurso = () => {
  const { slug } = useParams();
  const [torneo, setTorneo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const GetTorneos = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}torneo/slug/${slug}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("TokenPublic")}`,
              "Content-Type": "application/json",
              "X-Rol": `${localStorage.getItem("Rol")}`,
            },
          }
        );
        setTorneo(response.data);
      } catch (error) {
        console.error("Error al obtener los Torneos:", error);
      } finally {
        setLoading(false);
      }
    };

    GetTorneos();
  }, [slug]);

  const [activeTab, setActiveTab] = useState("course-pills-tab-1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const iframeRef = useRef(null);
  const videoUrl = "https://www.tiktok.com/embed/7386017231840218373";

  useEffect(() => {
    const iframe = iframeRef.current;
    let interval;

    const startVideo = () => {
      if (iframe) {
        iframe.src = videoUrl;
      }
    };

    const restartVideo = () => {
      clearInterval(interval);
      startVideo();
      interval = setInterval(startVideo, 12000); // Reinicia cada 121 segundos (2 minutos y 1 segundo)
    };

    // Inicia el video y establece el intervalo para reiniciar
    startVideo();
    interval = setInterval(startVideo, 121000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function getIdFromYouTubeShortsUrl(url) {
    // Verificar si es el formato de YouTube Shorts (/shorts/video_id)
    const match = url.match(/\/shorts\/(\w+)/);
    if (match) {
      return match[1];
    }
    return "";
  }

  function getIdFromYouTubeUrl(url) {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/
    );
    if (match) {
      return match[1];
    }
    return "";
  }

  function getIdFromTikTokUrl(url) {
    const match = url.match(/\/video\/(\d+)/);
    if (match) {
      return match[1];
    }
    return "";
  }
  return (
    <>
      <Navbar />

      {loading ? (
        <div className="d-flex justify-content-center align-items-center m-7">
          <div className="text-center">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <>
          <section className="position-relative torneo-section">
            <img
              src={`${window.BACKEND_URL}uploads/${torneo?.photo}`}
              className="img-fluid w-100 d-block d-md-none"
              alt={torneo?.Titulo}
              id="torneo-image-mobile"
            />
            <div className="container torneo-content">
              <div className="row">
                <div className="col-12 col-md-6 ms-md-4">
                  <h2 className="text-start text-azul-stemis">
                    {torneo?.Titulo}
                  </h2>
                  <p>{torneo?.SubTitulo}</p>
                  {/*   <Link
                    className="btn btn-sm btn-success-soft mb-2"
                    to={`/concurso/inscripcion/${torneo?._id}`}
                  >
                    <i className="fa-solid fa-person-chalkboard me-2"></i>
                    Inscribe a tu equipo
                  </Link>
                  <br /> */}
                  <br />
                  <Link
                    className="btn btn-sm btn-success-shadow"
                    to="https://concursos.stemis.com.pe"
                  >
                    <i className="fa-solid fa-right-to-bracket me-2"></i>
                    Plataforma sTemis concursos
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <style jsx>{`
            .torneo-section {
              position: relative;
              overflow: hidden;
            }

            .torneo-section::before {
              content: "";
              background-image: url("${window.BACKEND_URL}uploads/${torneo?.photo2}");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: -1;
              display: none;
            }

            .torneo-content {
              position: relative;
              z-index: 1;
              padding-top: 20px;
              padding-bottom: 20px;
            }

            #torneo-image-mobile {
              margin-top: 20px;
            }

            @media (min-width: 768px) {
              .torneo-section::before {
                display: block;
              }

              #torneo-image-mobile {
                display: none;
              }
            }
          `}</style>

          <div className="container-fluid">
            <div className="position-relative">
              <ul
                className="nav nav-pills nav-pill-soft mx-lg-5 px-lg-5"
                id="course-pills-tab"
                role="tablist"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <li
                  className="nav-item"
                  role="presentation"
                  style={{ flex: 1 }}
                >
                  <button
                    id="course-pills-tab-1"
                    data-bs-toggle="pill"
                    data-bs-target="#course-pills-tab1"
                    role="tab"
                    aria-controls="course-pills-tab1"
                    aria-selected={activeTab === "course-pills-tab-1"}
                    onClick={() => handleTabClick("course-pills-tab-1")}
                    style={{
                      width: "100%",
                      height: "70px",
                      backgroundColor:
                        activeTab === "course-pills-tab-1"
                          ? "#DEAE07"
                          : "white",
                      border: "1px solid white",
                      borderRadius: "0",
                      color:
                        activeTab === "course-pills-tab-1"
                          ? "white"
                          : "#DEAE07",
                      padding: "0",
                      position: "relative",
                      overflow: "hidden", // Oculta el contenido que se desborda
                    }}
                    className="rounded-4"
                  >
                    <i className="fa-solid fa-bullhorn fs-3 mb-1 me-sm-2"></i>
                    <span className="d-none d-md-inline-block fw-bold">
                      Novedades
                    </span>
                    <span className="d-md-none visually-hidden fw-bold">
                      Novedades
                    </span>{" "}
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  style={{ flex: 1 }}
                >
                  <button
                    id="course-pills-tab-2"
                    data-bs-toggle="pill"
                    data-bs-target="#course-pills-tab2"
                    role="tab"
                    aria-controls="course-pills-tab2"
                    aria-selected={activeTab === "course-pills-tab-2"}
                    onClick={() => handleTabClick("course-pills-tab-2")}
                    style={{
                      width: "100%",
                      height: "70px",
                      backgroundColor:
                        activeTab === "course-pills-tab-2"
                          ? "#009688"
                          : "white",
                      border: "1px solid white",
                      borderRadius: "0",
                      color:
                        activeTab === "course-pills-tab-2"
                          ? "white"
                          : "#009688",
                    }}
                    className="rounded-4"
                  >
                    <i className="fa-regular fa-file fs-3 mb-1 me-sm-2"></i>
                    <span className="d-none d-md-inline-block fw-bold">
                      Presentación
                    </span>
                    <span className="d-md-none visually-hidden fw-bold">
                      Presentación
                    </span>
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  style={{ flex: 1 }}
                >
                  <button
                    id="course-pills-tab-3"
                    data-bs-toggle="pill"
                    data-bs-target="#course-pills-tab3"
                    role="tab"
                    aria-controls="course-pills-tab3"
                    aria-selected={activeTab === "course-pills-tab-3"}
                    onClick={() => handleTabClick("course-pills-tab-3")}
                    style={{
                      width: "100%",
                      height: "70px",
                      backgroundColor:
                        activeTab === "course-pills-tab-3"
                          ? "#00B586"
                          : "white",
                      border: "1px solid white",
                      borderRadius: "0",
                      color:
                        activeTab === "course-pills-tab-3"
                          ? "white"
                          : "#00B586",
                    }}
                    className="rounded-4"
                  >
                    <i className="fa-solid fa-database fs-3 mb-1 me-sm-3"></i>
                    <span className="d-none d-md-inline-block fw-bold">
                      Bases
                    </span>
                    <span className="d-md-none visually-hidden fw-bold">
                      Bases
                    </span>
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  style={{ flex: 1 }}
                >
                  <button
                    id="course-pills-tab-4"
                    data-bs-toggle="pill"
                    data-bs-target="#course-pills-tab4"
                    role="tab"
                    aria-controls="course-pills-tab4"
                    aria-selected={activeTab === "course-pills-tab-4"}
                    onClick={() => handleTabClick("course-pills-tab-4")}
                    style={{
                      width: "100%",
                      height: "70px",
                      backgroundColor:
                        activeTab === "course-pills-tab-4"
                          ? "#454C5B"
                          : "white",
                      border: "1px solid white",
                      borderRadius: "0",
                      color:
                        activeTab === "course-pills-tab-4"
                          ? "white"
                          : "#454C5B",
                    }}
                    className="rounded-4"
                  >
                    <i className="fa-solid fa-suitcase fs-3 mb-1 me-sm-3"></i>
                    <span className="d-none d-md-inline-block fw-bold">
                      Caso
                    </span>
                    <span className="d-md-none visually-hidden fw-bold">
                      Caso
                    </span>
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  style={{ flex: 1 }}
                >
                  <button
                    id="course-pills-tab-4"
                    data-bs-toggle="pill"
                    data-bs-target="#course-pills-tab5"
                    role="tab"
                    aria-controls="course-pills-tab5"
                    aria-selected={activeTab === "course-pills-tab-5"}
                    onClick={() => handleTabClick("course-pills-tab-5")}
                    style={{
                      width: "100%",
                      height: "70px",
                      backgroundColor:
                        activeTab === "course-pills-tab-5"
                          ? "#6ED9E3"
                          : "white",
                      border: "1px solid white",
                      borderRadius: "0",
                      color:
                        activeTab === "course-pills-tab-5"
                          ? "white"
                          : "#6ED9E3",
                    }}
                    className="rounded-4"
                  >
                    <i className="fa-solid fa-circle-check fs-3 mb-1 me-sm-3"></i>
                    <span className="d-none d-md-inline-block fw-bold">
                      Aclaraciones
                    </span>
                    <span className="d-md-none visually-hidden fw-bold">
                      Aclaraciones
                    </span>
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  style={{ flex: 1 }}
                >
                  <button
                    id="course-pills-tab-6"
                    data-bs-toggle="pill"
                    data-bs-target="#course-pills-tab6"
                    role="tab"
                    aria-controls="course-pills-tab6"
                    aria-selected={activeTab === "course-pills-tab-6"}
                    onClick={() => handleTabClick("course-pills-tab-6")}
                    style={{
                      width: "100%",
                      height: "70px",
                      backgroundColor:
                        activeTab === "course-pills-tab-6"
                          ? "#CF214F"
                          : "white",
                      border: "1px solid white",
                      borderRadius: "0",
                      color:
                        activeTab === "course-pills-tab-6"
                          ? "white"
                          : "#CF214F",
                    }}
                    className="rounded-4"
                  >
                    <i class="fa-regular fa-calendar-plus fs-3 mb-1 me-lg-2 ms-sm-3"></i>
                    <span className="d-none d-md-inline-block fw-bold">
                      Pautas
                    </span>
                    <span className="d-md-none visually-hidden fw-bold">
                      Pautas
                    </span>
                  </button>
                </li>
              </ul>

              <div
                className="tab-content mb-0 mb-5"
                id="course-pills-tabContent"
              >
                {/*   novedades */}
                <div
                  id="course-pills-tab1"
                  className={`tab-pane fade ${
                    activeTab === "course-pills-tab-1" ? "show active" : ""
                  }`}
                  role="tabpanel"
                  aria-labelledby="course-pills-tab-1"
                >
                  <div
                    className="container-fluid"
                    style={{ minHeight: "80vh", position: "relative" }}
                  >
                    <div className="row mx-lg-7 mt-3">
                      <div className="col-lg-7  col-12 mb-3">
                        <div
                          className="card bg-opacity-100 shadow-custom p-3 mt-3  rounded-5"
                          style={{
                            backgroundImage:
                              'url("/assets/images/stemisp/dibujos atras.svg")',
                            backgroundSize: "100%", // Reducir el tamaño de la imagen de fondo
                            backgroundPosition: "left -170px bottom -120px", // Ajustar la posición con valores en píxeles// Ajustar la posición con valores en píxeles
                            backgroundRepeat: "no-repeat", // Evitar que la imagen se repita
                            minHeight: "300px", // Altura mínima para asegurar que la tarjeta tenga suficiente espacio
                            position: "relative",
                            backgroundColor: "rgba(255, 255, 255, 0.5)",
                          }}
                        >
                          <div
                            className="m-lg-4 texto-con-sombra"
                            style={{ textAlign: "justify", color: "black" }}
                            dangerouslySetInnerHTML={{
                              __html: torneo?.Novedades,
                            }}
                          ></div>
                        </div>

                        <div className="mb-3">
                          {torneo?.Novedad?.slice()
                            .reverse()
                            .map((novedad, index) => (
                              <div
                                className="row mt-3"
                                key={novedad.NovedadId.$oid}
                              >
                                <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                                  <p className="mb-0 fs-5 text-white custom-border">
                                    <strong>
                                      {new Date(novedad.create).getDate()}/
                                      {new Date(novedad.create).getMonth() + 1}/
                                      {new Date(novedad.create).getFullYear()}
                                    </strong>
                                  </p>
                                </div>
                                <div className="col-12 d-flex flex-wrap justify-content-center">
                                  {torneo?.Novedad?.map((novedad, index) => (
                                    <div
                                      key={index}
                                      className="card bg-opacity-10 shadow p-3 m-2 rounded-5"
                                      style={{
                                        width: "100%",
                                        maxWidth: "500px",
                                      }}
                                    >
                                      {(() => {
                                        switch (novedad.tipo) {
                                          case 1:
                                            return (
                                              <img
                                                src={`${window.BACKEND_URL}uploads/${novedad.content}`}
                                                alt={`Novedad ${index + 1}`}
                                                className="card-img-top img-fluid rounded-5"
                                              />
                                            );
                                          case 3:
                                            return (
                                              <div className="ratio ratio-16x9">
                                                <iframe
                                                  src={`https://www.youtube.com/embed/${getIdFromYouTubeUrl(
                                                    novedad.content
                                                  )}`}
                                                  title={`YouTube video ${
                                                    index + 1
                                                  }`}
                                                  allowFullScreen
                                                  className="rounded-5"
                                                ></iframe>
                                              </div>
                                            );
                                          case 4:
                                            // Para video de TikTok
                                            return (
                                              <div className="d-flex justify-content-center">
                                                <div className="video-container">
                                                  {novedad.content ? (
                                                    <iframe
                                                      title="TikTok Video"
                                                      src={`https://www.tiktok.com/embed/${getIdFromTikTokUrl(
                                                        novedad.content
                                                      )}`}
                                                      width="325"
                                                      height="550"
                                                      frameBorder="0"
                                                      allowFullScreen
                                                      className="video-iframe rounded-5"
                                                      style={{
                                                        margin: "0 auto",
                                                      }} // Esto asegura que el iframe esté centrado
                                                    ></iframe>
                                                  ) : (
                                                    <p>
                                                      No hay video disponible
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            );

                                          default:
                                            return (
                                              <div
                                                className="card-body"
                                                dangerouslySetInnerHTML={{
                                                  __html: novedad.content,
                                                }}
                                              />
                                            );
                                        }
                                      })()}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="col-lg-5 col-12">
                        <div
                          className="video-preview mt-3"
                          style={{ position: "sticky", top: "20px" }}
                        >
                          {/* Aquí podría ir tu contenido de video o cualquier otro elemento */}
                          <iframe
                            ref={iframeRef}
                            src={videoUrl}
                            frameBorder="0"
                            allowFullScreen
                            className="w-100"
                            height="590px" // Ajusta la altura según sea necesario
                            scrolling="no"
                          ></iframe>
                          <Contactanos />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Presentacion */}
                <div
                  id="course-pills-tab2"
                  className={`tab-pane fade ${
                    activeTab === "course-pills-tab-2" ? "show active" : ""
                  }`}
                  role="tabpanel"
                  aria-labelledby="course-pills-tab-2"
                >
                  <div className="row mx-lg-7  mt-3">
                    <div className="col-lg-6">
                      <div
                        className="card bg-opacity-100 shadow-custom p-3 mt-2 rounded-5"
                        style={{
                          backgroundImage:
                            'url("/assets/images/stemisp/dibujos atras.svg")',
                          backgroundSize: "100%", // Reducir el tamaño de la imagen de fondo
                          backgroundPosition: "left -160px bottom -110px", // Ajustar la posición con valores en píxeles// Ajustar la posición con valores en píxeles
                          backgroundRepeat: "no-repeat", // Evitar que la imagen se repita
                          minHeight: "300px", // Altura mínima para asegurar que la tarjeta tenga suficiente espacio
                          position: "relative",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                        }}
                      >
                        {torneo?.Presentacion ? (
                          <div
                            style={{ textAlign: "justify", color: "black" }}
                            className="m-lg-4 texto-con-sombra"
                            dangerouslySetInnerHTML={{
                              __html: torneo?.Presentacion,
                            }}
                          ></div>
                        ) : (
                          <h2 className="text-center">Próximamente</h2>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="card p-2 pb-0 bg-opacity-100 shadow rounded-5">
                        <p className="fs-4 ms-3 fw-bolder text-verde-stemis">
                          Cronograma
                        </p>
                        <img
                          src={`${window.BACKEND_URL}uploads/${torneo?.photo3}`}
                          className="card-img-top rounded-5 mb-3"
                          alt="course image"
                        />
                      </div>
                      <Contactanos />
                    </div>
                  </div>
                </div>
                {/* BASES */}
                <div
                  id="course-pills-tab3"
                  className={`tab-pane fade ${
                    activeTab === "course-pills-tab-3" ? "show active" : ""
                  }`}
                  role="tabpanel"
                  aria-labelledby="course-pills-tab-3"
                >
                  <div className="row mx-lg-7  mt-3">
                    {torneo?.Bases || torneo?.docBases ? (
                      <>
                        <div className="col-lg-5">
                          <div
                            className="card bg-opacity-100 shadow-custom p-3 mt-2 rounded-5"
                            style={{
                              backgroundImage:
                                'url("/assets/images/stemisp/dibujos atras.svg")',
                              backgroundSize: "100%", // Reducir el tamaño de la imagen de fondo
                              backgroundPosition: "left -160px bottom -110px", // Ajustar la posición con valores en píxeles// Ajustar la posición con valores en píxeles
                              backgroundRepeat: "no-repeat", // Evitar que la imagen se repita
                              minHeight: "300px", // Altura mínima para asegurar que la tarjeta tenga suficiente espacio
                              position: "relative",
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "justify",
                                color: "black",
                              }}
                              className="m-lg-4 texto-con-sombra"
                              dangerouslySetInnerHTML={{
                                __html: torneo?.Bases,
                              }}
                            ></div>
                            <div className="text-center">
                              <a
                                className="p-3 rounded-5 btn btn-success"
                                target="_blank"
                                href={`${window.BACKEND_URL}uploads/${torneo?.docBases}`}
                                download={`${window.BACKEND_URL}uploads/${torneo?.docBases}`}
                              >
                                <i className="fa-solid fa-download me-1"></i>
                                DOCUMENTO
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-7">
                          <div className="card p-2 pb-0 shadow rounded-5 mt-sm-5">
                            <div className="overflow-hidden h-xl-500px justify-content-center">
                              <div className="m-auto">
                                <iframe
                                  src={`${window.BACKEND_URL}uploads/${torneo?.docBases}`}
                                  type="application/pdf"
                                  className="embed-responsive-item rounded-5"
                                  style={{ width: "100%", height: "500px" }} // Ajusta la altura según tus necesidades
                                ></iframe>
                              </div>
                            </div>
                          </div>
                          <Contactanos />
                        </div>
                      </>
                    ) : (
                      <div className="col-12">
                        <div className="card bg-opacity-100 shadow-lg p-5 mt-1 rounded-5 text-center">
                          <h2>Próximamente</h2>
                          <Contactanos />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/*       CASO */}
                <div
                  id="course-pills-tab4"
                  className={`tab-pane fade ${
                    activeTab === "course-pills-tab-4" ? "show active" : ""
                  }`}
                  role="tabpanel"
                >
                  <div className="row mx-lg-7  mt-3">
                    {torneo?.Caso || torneo?.docCaso ? (
                      <>
                        <div className="col-lg-5">
                          <div
                            className="card bg-opacity-100 shadow-lg p-3 mt-1 rounded-5"
                            style={{
                              backgroundImage:
                                'url("/assets/images/stemisp/dibujos atras.svg")',
                              backgroundSize: "100%", // Reducir el tamaño de la imagen de fondo
                              backgroundPosition: "left -160px bottom -110px", // Ajustar la posición con valores en píxeles// Ajustar la posición con valores en píxeles
                              backgroundRepeat: "no-repeat", // Evitar que la imagen se repita
                              minHeight: "300px", // Altura mínima para asegurar que la tarjeta tenga suficiente espacio
                              position: "relative",
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                            }}
                          >
                            <div
                              className="m-lg-4 texto-con-sombra"
                              style={{
                                textAlign: "justify",
                                color: "black",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: torneo?.Caso,
                              }}
                            ></div>
                            <div className="text-center">
                              <a
                                className="p-3 rounded-5 btn btn-success"
                                target="_blank"
                                href={`${window.BACKEND_URL}uploads/${torneo?.docCaso}`}
                                download={`${window.BACKEND_URL}uploads/${torneo?.docCaso}`}
                              >
                                <i className="fa-solid fa-download me-1"></i>
                                DOCUMENTO
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-7">
                          <div className="card p-2 pb-0 shadow rounded-5">
                            <div className="overflow-hidden h-xl-550px justify-content-center">
                              <div className="m-auto">
                                <iframe
                                  src={`${window.BACKEND_URL}uploads/${torneo?.docCaso}`}
                                  type="application/pdf"
                                  className="embed-responsive-item rounded-5"
                                  style={{ width: "100%", height: "500px" }} // Ajusta la altura según tus necesidades
                                ></iframe>
                              </div>
                            </div>
                          </div>
                          <Contactanos />
                        </div>
                      </>
                    ) : (
                      <div className="col-12">
                        <div className="card bg-opacity-100 shadow-lg p-5 mt-1 rounded-5 text-center">
                          <h2>Próximamente</h2>
                          <Contactanos />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ACLARACIONES */}
                <div
                  id="course-pills-tab5"
                  className={`tab-pane fade ${
                    activeTab === "course-pills-tab-5" ? "show active" : ""
                  }`}
                  role="tabpanel"
                  aria-labelledby="course-pills-tab-5"
                >
                  <div className="row mx-lg-7 mt-3">
                    {torneo?.Aclaraciones || torneo?.docAclaraciones ? (
                      <>
                        <div className="col-lg-5">
                          <div
                            className="card bg-opacity-100 shadow-lg p-3 mt-1 rounded-5"
                            style={{
                              backgroundImage:
                                'url("/assets/images/stemisp/dibujos atras.svg")',
                              backgroundSize: "100%", // Reducir el tamaño de la imagen de fondo
                              backgroundPosition: "left -160px bottom -110px", // Ajustar la posición con valores en píxeles// Ajustar la posición con valores en píxeles
                              backgroundRepeat: "no-repeat", // Evitar que la imagen se repita
                              minHeight: "300px", // Altura mínima para asegurar que la tarjeta tenga suficiente espacio
                              position: "relative",
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                            }}
                          >
                            <div
                              className="m-lg-4 texto-con-sombra"
                              style={{
                                textAlign: "justify",
                                color: "black",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: torneo?.Aclaraciones,
                              }}
                            ></div>
                            <div className="text-center">
                              <a
                                className="p-3 rounded-5 btn btn-success"
                                target="_blank"
                                href={`${window.BACKEND_URL}uploads/${torneo?.docAclaraciones}`}
                                download={`${window.BACKEND_URL}uploads/${torneo?.docAclaraciones}`}
                              >
                                <i className="fa-solid fa-download me-1"></i>
                                DOCUMENTO
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="card p-2 pb-0 shadow">
                            <div className="overflow-hidden h-xl-550px justify-content-center rounded-5">
                              <div className="m-auto">
                                <iframe
                                  src={`${window.BACKEND_URL}uploads/${torneo?.docAclaraciones}`}
                                  type="application/pdf"
                                  className="embed-responsive-item rounded-5"
                                  style={{ width: "100%", height: "500px" }} // Ajusta la altura según tus necesidades
                                ></iframe>
                              </div>
                            </div>
                          </div>
                          <Contactanos />
                        </div>
                      </>
                    ) : (
                      <div className="col-12">
                        <div className="card bg-opacity-100 shadow-lg p-5 mt-1 rounded-5 text-center">
                          <h2>Próximamente</h2>
                          <Contactanos />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/*    PAUTAS */}
                <div
                  id="course-pills-tab6"
                  className={`tab-pane fade ${
                    activeTab === "course-pills-tab-6" ? "show active" : ""
                  }`}
                  role="tabpanel"
                  aria-labelledby="course-pills-tab-6"
                >
                  {torneo?.Pautas ? (
                    <div className="row mx-lg-7  mt-3">
                      <div className="col-lg-5 ">
                        {torneo?.Pautas && (
                          <div
                            className="card bg-opacity-100 shadow-lg p-3 mt-1 rounded-5"
                            style={{
                              backgroundImage:
                                'url("/assets/images/stemisp/dibujos atras.svg")',
                              backgroundSize: "100%", // Reducir el tamaño de la imagen de fondo
                              backgroundPosition: "left -160px bottom -110px", // Ajustar la posición con valores en píxeles// Ajustar la posición con valores en píxeles
                              backgroundRepeat: "no-repeat", // Evitar que la imagen se repita
                              minHeight: "300px", // Altura mínima para asegurar que la tarjeta tenga suficiente espacio
                              position: "relative",
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                            }}
                          >
                            <div
                              className="m-lg-4 texto-con-sombra"
                              style={{
                                textAlign: "justify",
                                color: "black",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: torneo?.Pautas,
                              }}
                            ></div>
                            <div className="text-center ">
                              <a
                                className="p-3 rounded-5  rounded-5 btn btn-success"
                                target="_blank"
                                href={`${window.BACKEND_URL}uploads/${torneo?.docPautas}`}
                                download={`${window.BACKEND_URL}uploads/${torneo?.docPautas}`}
                              >
                                <i className="fa-solid fa-download me-1"></i>
                                DOCUMENTO
                              </a>
                            </div>
                          </div>
                        )}
                      </div>

                      {torneo?.docPautas && (
                        <div className="col-lg-7 ">
                          <div className="card p-2 pb-0 shadow ">
                            <div className="overflow-hidden h-xl-500px justify-content-center rounded-5 ">
                              <div className="m-auto">
                                <iframe
                                  src={`${window.BACKEND_URL}uploads/${torneo?.docPautas}`}
                                  type="application/pdf"
                                  className="embed-responsive-item rounded-5"
                                  style={{ width: "100%", height: "500px" }} // Ajusta la altura según tus necesidades
                                ></iframe>
                              </div>
                            </div>
                          </div>
                          <Contactanos />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="card bg-opacity-100 shadow-lg p-5 mt-1 rounded-5 text-center">
                        <h2>Próximamente</h2>
                        <Contactanos />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer />
    </>
  );
};

export default DetallesConcurso;

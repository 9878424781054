import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

ReactModal.setAppElement("#root");
const AulaDetalles = () => {
  const [matriculaId, setMatriculaId] = useState(null);
  const { slug } = useParams();
  const [curso, setCurso] = useState(null);
  const [docente, setDocente] = useState(null);
  const [documentosDescargados] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [tareas, setTareas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponsive] = useState(false);

  const [idTarea, setIdTarea] = useState(null);

  const handleOpenModal = (idTarea) => {
    setIdTarea(idTarea);
    setShowModal(true);
    setFile(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchCurso = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}curso/slug/${slug}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
            },
          }
        );
        setCurso(response.data);

        const docente_id = response.data.docente_id;
        const docenteResponse = await axios.get(
          `${window.BACKEND_URL}users/${docente_id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
            },
          }
        );
        setDocente(docenteResponse.data);

        // Compare curso_id with matriculas
        const curso_id = response.data._id;
        const matriculaResponse = await axios.get(
          `${window.BACKEND_URL}matricula`
        );
        const fetchedMatriculaId = matriculaResponse.data.find(
          (matricula) => matricula.curso_id === curso_id
        )?._id;

        setMatriculaId(fetchedMatriculaId);
      } catch (error) {
        console.error("Error fetching curso:", error);
      }
    };

    fetchCurso();
    fetchTareas();
  }, [slug]);

  const fetchTareas = async () => {
    try {
      const alumnoId = sessionStorage.getItem("alumnoId");
      const response = await axios.get(`${window.BACKEND_URL}tareas`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
        },
      });
      const tareasFiltradas = response.data.filter(
        (tarea) => tarea.alumno_id === alumnoId
      );
      setTareas(tareasFiltradas);
    } catch (error) {
      console.error("Error fetching tareas:", error);
    }
  };
  //metodo post de tarea
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      console.error("No se ha seleccionado ningún archivo");
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("alumno_id", sessionStorage.getItem("alumnoId"));
      formData.append("curso_id", curso._id);
      formData.append("tarea_id", idTarea);
      formData.append("matricula_id", matriculaId);
      formData.append("path", file);

      const response = await axios.post(
        `${window.BACKEND_URL}tareas`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      handleCloseModal();
      window.alert("Tarea subida exitosamente");
      window.location.reload();
    } catch (error) {
      console.error("Error al crear la tarea:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const [activeTab, setActiveTab] = useState("course-pills-1");

  const renderDocumentoIcono = (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    let icono;

    switch (fileExtension) {
      case "pdf":
        icono = <i className="fa-solid fa-file-signature fa-5x"></i>;

        break;
      case "doc":
      case "docx":
        icono = <i className="bi bi-file-word fa-5x"></i>;
        break;
      case "xls":
      case "xlsx":
        icono = <i className="bi bi-file-excel fa-5x"></i>;
        break;
      default:
        icono = <i className="bi bi-file fa-5x"></i>;
    }

    return icono;
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const renderDificultad = (nivel) => {
    let icono;
    let texto;

    switch (nivel) {
      case 1:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Básico";
        break;
      case 2:
      case 3:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Intermedio";
        break;
      case 4:
      case 5:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Avanzado";
        break;
      default:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Desconocido";
    }

    return (
      <>
        {icono} {texto}
      </>
    );
  };

  if (!curso || !docente) {
    return (
      <div>
        <Navbar />
        <div className="d-flex justify-content-center align-items-center m-7">
          <div className="text-center">
            <div className="loader"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  const documentos = curso.documentos || [];
  const links = curso.links || [];
  return (
    <>
      <Navbar />
      <section className="bg-success  bg-opacity-100 py-7">
        <div className="container">
          <div className="row justify-content-lg-between">
            <div className="col-lg-8">
              {/* Title */}
              <h1 className="text-white">{curso.nombre} </h1>
              <h6 className="text-white">{curso.materia}</h6>
              <p className="text-white">{curso.descripcion}</p>

              {/* Content */}
              <ul className="list-inline mb-5">
                <li className="list-inline-item h6 me-4 mb-1 mb-sm-0 text-white">
                  <span className="fw-light">Docente:</span> {docente.name}
                </li>

                <li className="list-inline-item h6 mb-0 text-white">
                  {renderDificultad(curso.dificultad)}
                </li>
              </ul>
            </div>

            <div className="col-lg-3">
              {/* Progress item */}
              <div className="overflow-hidden mb-4">
                <h6 className="text-white">Documentos</h6>
                <div className="progress progress-sm bg-white bg-opacity-10 mb-1">
                  <div
                    className="progress-bar bg-white aos"
                    role="progressbar"
                    data-aos="slide-right"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    style={{
                      width: `${
                        (documentosDescargados / curso.documentos.length) * 100
                      }%`,
                    }}
                    aria-valuenow={documentosDescargados}
                    aria-valuemin="0"
                    aria-valuemax={curso.documentos.length}
                  ></div>
                </div>
                <small className="text-white">
                  {documentosDescargados} de {curso.documentos.length}{" "}
                  Documentos
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow rounded-2 p-0 mt-n5">
                <div className="card-header border-bottom px-4 pt-3 pb-0">
                  <ul
                    className="nav nav-bottom-line py-0"
                    id="course-pills-tab"
                    role="tablist"
                  >
                    {/* Tab items */}
                    <li className="nav-item me-2 me-sm-4" role="presentation">
                      <button
                        className={`nav-link mb-2 mb-md-0 ${
                          activeTab === "course-pills-1" ? "active" : ""
                        }`}
                        id="course-pills-tab-1"
                        onClick={() => handleTabClick("course-pills-1")}
                        type="button"
                        role="tab"
                        aria-controls="course-pills-1"
                        aria-selected={activeTab === "course-pills-1"}
                      >
                        Materiales del curso
                      </button>
                    </li>
                    <li className="nav-item me-2 me-sm-4" role="presentation">
                      <button
                        className={`nav-link mb-2 mb-md-0 ${
                          activeTab === "course-pills-2" ? "active" : ""
                        }`}
                        id="course-pills-tab-2"
                        onClick={() => handleTabClick("course-pills-2")}
                        type="button"
                        role="tab"
                        aria-controls="course-pills-2"
                        aria-selected={activeTab === "course-pills-2"}
                      >
                        Tareas
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="card-body p-sm-4">
                  <div className="tab-content" id="course-pills-tabContent">
                    {/* Content for Course Materials */}
                    <div
                      className={`tab-pane fade ${
                        activeTab === "course-pills-1" ? "show active" : ""
                      }`}
                      id="course-pills-1"
                      role="tabpanel"
                      aria-labelledby="course-pills-tab-1"
                    >
                      <div
                        className="accordion accordion-icon accordion-border"
                        id="accordionExample2"
                      >
                        {/* Item */}
                        <div className="accordion-item mb-3">
                          <h6
                            className="accordion-header font-base"
                            id="heading-1"
                          >
                            <button
                              className="accordion-button fw-bold rounded d-flex collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse-1"
                              aria-expanded="true"
                              aria-controls="collapse-1"
                            >
                              Documentos
                              <span
                                className="text-secondary ms-auto pe-4"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Mark as completed"
                              ></span>
                            </button>
                          </h6>
                          <br />
                          <div
                            id="collapse-1"
                            className="accordion-collapse collapse show"
                            aria-labelledby="heading-1"
                            data-bs-parent="#accordionExample2"
                          >
                            {documentos.map((documento, index) => (
                              <div key={index} className="mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex align-items-center">
                                    <a
                                      data-gallery="office"
                                      href={`${window.BACKEND_URL}curso/${curso._id}/documentos/${documento.idDocumento}/download`}
                                      target="_blank"
                                      className="icon-md position-relative"
                                    >
                                      <i className="fa-solid fa-file-arrow-down fa-3x"></i>
                                    </a>

                                    {/* Content */}
                                    <div className="ms-3">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span
                                          className={`d-inline-block mb-0 h6 fw-normal ${
                                            isResponsive ? "text-truncate" : ""
                                          }`}
                                          style={{ maxWidth: "150px" }}
                                        >
                                          {documento.filename.split("-")[1]}
                                        </span>
                                      </div>
                                      <ul className="nav nav-divider small mb-0"></ul>
                                    </div>
                                  </div>
                                  <a
                                    href={`${window.BACKEND_URL}curso/${curso._id}/documentos/${documento.idDocumento}/download`}
                                    download
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    <i className="fa-solid fa-download me-1"></i>
                                    Descargar
                                  </a>
                                </div>
                                <hr />
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* Item */}
                        <div className="accordion-item mb-3">
                          <h6
                            className="accordion-header font-base"
                            id="heading-1"
                          >
                            <button
                              className="accordion-button fw-bold rounded d-flex collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse-2"
                              aria-expanded="true"
                              aria-controls="collapse-2"
                            >
                              Links
                              <span
                                className="text-secondary ms-auto pe-4"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Mark as completed"
                              ></span>
                            </button>
                          </h6>
                          <div
                            id="collapse-2"
                            className="accordion-collapse collapse show"
                            aria-labelledby="heading-2"
                            data-bs-parent="#accordionExample2"
                          >
                            <br />
                            {links.map((link, index) => (
                              <div key={index} className="mb-3">
                                <div className=" d-flex justify-content-between align-items-center">
                                  <div className="position-relative d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                      {/* Link button */}
                                      <a
                                        href={link.enlace}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="icon-md mb-0 position-static flex-shrink-0 text-body"
                                      >
                                        <i className="fas fa-fw fa-link fs-5"></i>
                                      </a>
                                      {/* Content */}
                                      <div className="ms-3">
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={link.enlace}
                                          className="d-inline-block text-truncate mb-0 h6 fw-normal"
                                          title={link.nombre}
                                        >
                                          {link.nombre}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* Item */}
                        {/* ... More items */}
                      </div>
                      {/* Accordion END */}
                    </div>
                    {/* Content for Notes */}
                    <div
                      className={`tab-pane fade ${
                        activeTab === "course-pills-2" ? "show active" : ""
                      }`}
                      id="course-pills-2"
                      role="tabpanel"
                      aria-labelledby="course-pills-tab-2"
                    >
                      <div className="d-sm-flex justify-content-between align-items-center">
                        <h4 className="mb-0">Todas las tareas</h4>
                      </div>
                      <div>
                        {curso.tareas.map((tarea, index) => (
                          <div key={index} className="card mb-3">
                            <div className="card-header border-bottom p-0 pb-3">
                              <div className="d-sm-flex justify-content-between align-items-center"></div>
                            </div>
                            <div className="card-body p-0 pt-3">
                              <div className="row g-4">
                                <div className="col-sm-6 col-xl-1">
                                  {renderDocumentoIcono(tarea.filename)}
                                </div>
                                <div className="col-sm-10 col-xl-11">
                                  <h5 className="mb-0">
                                    {tarea.filename.substring(
                                      tarea.filename.indexOf("-") + 1
                                    )}
                                  </h5>
                                  {tareas
                                    .filter(
                                      (tareaFiltrada) =>
                                        tareaFiltrada.tarea_id === tarea.idTarea
                                    )
                                    .sort(
                                      (a, b) =>
                                        new Date(b.fecha) - new Date(a.fecha)
                                    )
                                    .map((tareaFiltrada, index) => (
                                      <div key={index}>
                                        <h6>Última tarea:</h6>
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          className="text-success mb-0"
                                          href={`${window.BACKEND_URL}uploads/${tareaFiltrada.path}`}
                                        >
                                          {tareaFiltrada.path.substring(
                                            tareaFiltrada.path.lastIndexOf(
                                              "-"
                                            ) + 1
                                          )}
                                        </a>
                                      </div>
                                    ))}
                                  <p></p>
                                  <div className="hstack gap-3 mt-2 flex-wrap">
                                    <a
                                      href={`${window.BACKEND_URL}uploads/${tarea.filename}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="text-success mb-0"
                                    >
                                      <i className="bi bi-file-earmark-arrow-down"></i>
                                      Descargar tarea
                                    </a>
                                    <a
                                      className="btn btn-sm btn-primary mb-0"
                                      onClick={() =>
                                        handleOpenModal(tarea.idTarea)
                                      }
                                    >
                                      <i className="bi bi-upload me-2"></i>
                                      Enviar tarea
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab contents END */}
              </div>
            </div>
            {/* Main content END */}
          </div>
        </div>
      </section>
      <Footer />
      <ReactModal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
        contentLabel="Enviar tarea"
      >
        <h2>Enviar tarea</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="path" className="form-label">
              Seleccionar archivo:
            </label>
            <input
              type="file"
              className="form-control"
              id="path"
              name="path"
              onChange={handleFileChange}
            />
          </div>
          {/* Resto de los campos del formulario */}
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isLoading} // Deshabilitar el botón mientras isLoading sea true
          >
            {isLoading ? "Enviando..." : "Enviar"}
          </button>
        </form>
      </ReactModal>
    </>
  );
};

export default AulaDetalles;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const Confirmar = () => {
  const { token } = useParams(); // Obtener el token de confirmación de la URL
  const navigate = useNavigate();
  const [confirmado, setConfirmado] = useState(false); // Estado para controlar si el correo ha sido confirmado
  const [loading, setLoading] = useState(true); // Estado para controlar la carga mientras se confirma el correo

  useEffect(() => {
    // Función para confirmar el correo automáticamente al cargar el componente
    const confirmarCorreo = async () => {
      try {
        const response = await axios.post(
          `${window.BACKEND_URL}alumno/confirmacion/${token}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("TokenPublic")}`,
              "X-Rol": `${localStorage.getItem("Rol")}`,
            },
          }
        );
        if (response.status === 200) {
          setConfirmado(true);
        } else {
          // Mostrar un mensaje más específico sobre el error
          alert(
            "No se pudo confirmar el correo. El enlace de confirmación es inválido."
          );
        }
      } catch (error) {
        console.error("Error al confirmar el correo:", error);
        // Mostrar un mensaje más específico sobre el error
        alert(
          "Ocurrió un error al confirmar el correo. Por favor, inténtalo de nuevo más tarde."
        );
      } finally {
        setLoading(false);
      }
    };

    confirmarCorreo();
  }, [token]);

  const LoginAlumno = () => {
    navigate("/LoginAlumno"); // Redirige a la ruta /login
  };

  const containerStyle = {
    background: "#009688",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    padding: "20px",
  };
  if (loading) {
    return (
      <div style={containerStyle}>
        <h1 className="text-white">Confirmar Registro</h1>
        <p>Confirmando correo electrónico...</p>
      </div>
    );
  }

  if (confirmado) {
    return (
      <div style={containerStyle}>
        <h1 className="text-white">Correo Confirmado</h1>
        <p>Tu correo ha sido confirmado correctamente.</p>
        <button className="btn btn-primary" onClick={LoginAlumno}>
          Ir a Login
        </button>
      </div>
    );
  }

  // Si hay un error, también se centrará verticalmente y horizontalmente
  return (
    <div style={containerStyle}>
      <h1 className="text-white">Error de Confirmación</h1>
      <p>
        Ocurrió un error al confirmar el correo. Por favor, intenta nuevamente
        más tarde.
      </p>
    </div>
  );
};

export default Confirmar;
